.container {
    padding: 20px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ngoImage {
    width: 100px;
    height: 80px;
    object-fit: contain;
    cursor: pointer;
}