.header {
    text-align: center;
    padding: 0px 10px;
    width: 80%;
    margin: 0px auto 0px;
}

.header h2 {
    color: orange;
    margin-bottom: 10px;
    font-size: 28px;
}

.header p {
    font-size: 13px;
    color: #fff;
}

.cityContainer {
    margin-top: 40px;
}

.title {
    font-size: 16px;
    color: #fff;
}

.cardWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
}

.card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    cursor: pointer;
    border-radius: 6px;
    aspect-ratio: 1/1;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.activeCard {
    border: 1px solid #035DB8;
    color: #035DB8;
    background-color: #ECF5FF;
    box-shadow: none;
}

.activeCard>p {
    font-weight: 600;
}