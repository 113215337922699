.container {
    width: 100%;
    height: 70px;
    background: rgb(0, 212, 255);
    background: linear-gradient(184deg, #0d99ff 0%, #0768cf 42%);
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 0px 40px;
}

.headerContent {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navLinks {
    align-items: center;
    gap: 32px;
}

.link {
    text-decoration: none;
    color: #fff;
}

.organizationLogo {
    width: 80px;
    object-fit: contain;
}

.navLink {
    text-align: center;
    color: #000;
    text-decoration: none;
    padding: 10px 0px;
}