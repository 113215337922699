.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.form {
    max-width: 100%;
    padding: 40px 20px;
    min-width: 800px;
    max-width: 100%;
}

.sections {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.orgInputsContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 10px;
    flex: 1;
}

.contactPersonsContanier {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 10px;
    flex: 1;
}

@media screen and (max-width: 800px) {
    .form {
        min-width: unset;
        width: 100%;
    }
    .sections {
        flex-direction: column;
    }

}