.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

.form {
    width: 400px;
    max-width: 95%;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 32px;
}

.formControls {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
}

.logo {
    font-family: fantasy !important;
    font-size: 36px;
}

.description {
    font-size: 14px;
    color: #00000091;
    max-width: 70%;
    margin: 8px auto 0;
}

.header {
    text-align: center;
}

/* .forgotPasswordTxt {
    color: var(--primary-color);
} */