.conatiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    text-align: center;
}

.imageContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageContainer img {
    width: 120px;
    object-fit: contain;
}

.name {
    font-size: 18px;
}

.description {
    font-size: 12px;
    margin-top: 8px;
}

.aboutDesc {
    font-size: 12px;
}

.lastYearDontation {
    width: 100%;
}

.donationDetails {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.donationDetails>div {
    display: flex;
    flex-direction: column;
    gap: 0px;
    flex: 1;
    border: 1px solid #FF6CA9;
    padding: 18px;
    border-radius: 6px;
}