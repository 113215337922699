.container {
    width: 100vw;
    height: 100vh;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;
}

.form {
    width: 400px;
    max-width: 100%;
    box-shadow: var(--box-shadow);
    padding: 40px 20px;
    border-radius: 14px;
}

.errorContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    box-shadow: var(--box-shadow);
    padding: 40px 20px;
    border-radius: 20px;
}