.header {
    text-align: center;
    padding: 0px 10px;
    width: 80%;
    margin: 0px auto 0px;
}

.header h2 {
    color: orange;
    margin-bottom: 10px;
    font-size: 28px;
}

.header p {
    font-size: 13px;
    color: #fff;
}

.title {
    font-size: 16px;
    color: #fff;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}