.date-picker {
    height: 40px;
    padding: 0px 8px;
    border-radius: 8px;
    border: 1px solid var(--color-grey);
}

.error-input {
    border: 1px solid var(--color-danger);
}

.fullwidth-input {
    width: 100%;
}

@media screen and (max-width: 720px) {
    .date-picker {
        font-size: 12px;
    }
}

.helper-text {
    color: red;
    font-size: 14px;
    margin-top: 2px;
}