.container {
    text-align: center;
    padding: 150px 30px;
    position: relative;
}

.container h2 {
    font-size: 22px;
}

.container p {
    font-size: 14px;
    margin-top: 20px;
    padding: 0px 25px;
}

.heart1 {
    position: absolute;
    top: 20%;
    right: 10px;
}

.heart2 {
    position: absolute;
    top: 50%;
    right: 4px;
}

.heart3 {
    position: absolute;
    top: 45%;
    right: 18%;
    transform: rotate(330deg);
}

.heart4 {
    position: absolute;
    top: 51%;
    left: 23%;
    transform: rotate(309deg);
}

.heart5 {
    position: absolute;
    top: 38%;
    left: 10%;
    transform: rotate(300deg);
}

.heart6 {
    position: absolute;
    top: 29%;
    left: 20%;
    transform: rotate(300deg);
}

.heart7 {
    position: absolute;
    top: 20%;
    left: 4%;
    transform: rotate(300deg);
}

.heart8 {
    position: absolute;
    top: 15%;
    left: 46%;
    transform: rotate(300deg);
}

.heart9 {
    position: absolute;
    top: 20%;
    right: 20%;
    transform: rotate(330deg);
}