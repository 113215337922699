.textarea {
    box-sizing: border-box;
    border: 1px solid #e0e0e0;
    outline: none;
    border-radius: 6px;
    max-width: 100%;
    width: 300px;
    padding: 10px;
    margin-top: 6px;
}

.textarea:focus {
    border: 2px solid#035DB8;
}

.textarea:hover {
    border-color: #000;
}

.fullWidth {
    min-width: 100% !important;
    width: 100% !important;
}

.error {
    border-color: #d32f2f !important;
}

.error:focus {
    border-color: #d32f2f !important;
}

@media screen and (max-width: 720px) {
    .textarea {
        font-size: 12px;
    }
}