.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.form {
    width: 500px;
    max-width: 100%;
    padding: 40px 20px;
}

.inputsContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 30px;
}

.orgLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.organizationLogo {
    width: 120px;
    object-fit: contain;
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
}