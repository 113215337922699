.container {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
}

.form {
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin: 20px 0px 8px;
}