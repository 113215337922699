.input-root fieldset {
    border: 1px solid #E0E0E0;
    outline: none;
    border-radius: 6px;
}

.input-root input {
    padding: 10px;
    font-size: 14px;
}

@media screen and (max-width: 720px) {

    .input-root input {
        font-size: 12px;
    }
}

.helper-text {
    color: red;
    font-size: 14px;
    margin-top: 2px;
}

.eyeBtn {
    position: absolute !important;
    right: 5px;
    top: 30px;
}