.legends {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.legend {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}

.dot {
    width: 16px;
    height: 16px;
    border-radius: 100%;
}

.chart {
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;
    margin: 20px 0px 60px;
    border-bottom: 1px solid #E5E5EF;
}