.container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.content {
    width: 100%;
    height: calc(100% - 70px);
    overflow-x: hidden;
    overflow-y: auto;
}