.header {
    text-align: center;
    padding: 0px 10px;
}

.headingContainer {
    margin-bottom: 10px;
}

.header h2 {
    color: orange;
    font-size: 28px;
}

.header>p {
    color: #fff;
    font-size: 13px;
}

.descriptions {
    font-size: 12px;
    color: #fff;
    padding: 0px 100px;
}

@media screen and (max-width: 720px) {
    .descriptions {
        padding: 0px 40px;
    }
}

.primaryHeader {
    font-size: 16px;
    color: #fff;
}

.secondaryHeader {
    font-size: 11px;
    color: #fff;
}

.content {
    margin-top: 40px;
}

.cardWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;
}

.sectorBadge {
    position: absolute;
    right: 0px;
    top: -12px;
    background-color: var(--secondary-color-light);
    color: var(--secondary-color);
    padding: 4px 12px;
    text-transform: capitalize;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    border-radius: 100px;
}

.detailedName {
    font-size: 11px;
    font-weight: 600;
}

.input {
    width: 100%;
    border: 1px solid #E0E0E0;
    background-color: transparent;
    height: 36px;
    padding: 10px;
    border-radius: 6px;
    outline: none;
}

.input::placeholder {
    color: #DEDEDE;
}

/* NGO Card */

.card {
    position: relative;
    border-radius: 16px;
    border: 1px solid #EAEAEA;
    background-color: #F5F5F5;
}

.selectedCard {
    background-color: var(--primary-color-light);
}

.ngoCard {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    border-radius: 16px;
    padding: 16px;
}

.ngoDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ngoDetailsLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1;
}

.checkBoxContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.voteText {
    color: green;
    font-size: 14px;
    font-weight: 500;

    /* display: inline-block;
    padding: 3px 8px;
    font-size: 12px;
    color: #fff;
    border-radius: 10px;
    background-color: #6cfd8d;
    background-image: linear-gradient(to bottom, transparent, rgb(77 77 77 / 50%));
    position: absolute;
    bottom: 25px;
    right: 10px; */
}

.ngoName {
    font-size: 18px;
    font-weight: 600;
}

.ngoDescription {
    font-size: 12px;
}

.totalDonations{
    font-size: 12px;
}

.totalDonations span {
    font-size: 14px;
    color: green;
    font-weight: 500;
}

.viewMoreBtn {
    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 4px !important;
}

.ngoImage {
    width: 100px;
    object-fit: contain;
}

.donateCard {
    border-radius: 16px;
    padding: 14px;
}

.donateCard>p {
    font-size: 13px;
}