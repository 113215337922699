.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    background-image: url("/public/background.jpg");
    /* background-size: cover;
    background-position: center; */
    width: 100%;
    height: 100%;
    position: relative;
    background-repeat: repeat;
}

.mainContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    filter: blur(2px);
    z-index: 1;
}

.wrapper {
    position: relative;
    width: 550px;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
}

.container {
    height: 100vh;
    overflow-y: auto;
}

.container::-webkit-scrollbar {
    display: none;
}

.top-overlay {
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 300px;
    z-index: -1;
    /* background: linear-gradient(45deg, #052f64, #36bffa);
     */
    background-position: top;
    background-attachment: absolute;
    background-repeat: no-repeat;
    background: rgb(0, 212, 255);
    background: linear-gradient(184deg, #0d99ff 0%, #0768cf 42%);
}

.gradient-overlay {
    position: absolute;
    z-index: 1;
    top: 299px;
    left: 0px;
    width: 100%;
    height: 100px;
    background: rgb(0, 95, 255);
    background: linear-gradient(180deg, #0768cf 20%, #fff 100%);
}

.bottom-overlay {
    position: absolute;
    z-index: 1;
    top: 300px;
    left: 0px;
    width: 100%;
    height: calc(100% - 300px);
    z-index: -1;
    background: #fff;
    background-position: top;
    background-attachment: absolute;
    background-repeat: no-repeat;
}

.icon {
    position: absolute;
    top: 100px;
    right: 0px;
    z-index: 1;
}

/* .top-overlay::after {
    content: '';
    position: absolute;
    height: 100px;
    left: 0px;
    bottom: 0px;
    width: 100%;
    background: linear-gradient(270deg, #83ffe7 20%, #83ffe700 50%);
    pointer-events: none;
} */

.page-content {
    position: relative;
    z-index: 2;
    padding: 20px;
}

/* Sidebar */

.sidebar {
    position: absolute;
    top: 0px;
    right: -280px;
    width: 270px;
    max-width: 100%;
    z-index: 1000;
    height: 100%;
    transition: all 0.5s ease-in-out;
    box-shadow: var(--box-shadow);
    background: linear-gradient(184deg, #0d99ff 0%, #0768cf 42%);
}

.open {
    right: 0px;
}

.navLinks>a {
    color: #fff;
    text-decoration: none;
    display: block;
    text-align: center;
    padding: 16px 14px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.navLinks>a>span {
    font-size: 14px;
}

.navLinks>a:hover {
    background-color: #0768cf;
}