@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Fredoka:wght@300..700&family=Playfair:ital,opsz,wght@0,5..1200,300..900;1,5..1200,300..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

:root {
  --primary-color: #035DB8;
  --primary-color-light: #ECF5FF;
  --secondary-color: orange;
  --secondary-color-light: #FFF0DA;
  --box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  --color-grey: #E6E6E6;
  --color-danger: red;
}

body {
  font-weight: 400;
  font-style: normal;
  color: #2A2A2A;
}

span {
  font-size: 12px;
}

.text-secondary {
  color: var(--secondary-color);
}

.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.helper-text {
  color: red;
  font-size: 14px;
  margin-top: 2px;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.text-white {
  color: #fff;
}

.text-center {
  text-align: center;
}

::-webkit-scrollbar {
  display: none;
}

.label {
  display: block;
  font-size: 15px;
}

@media screen and (max-width: 720px) {
  .label {
    font-size: 13px;
  }
}