.container {
    padding: 20px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cities {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.citySpan {
    padding: 4px 6px;
    background: #7b7b7b;
    border-radius: 8px;
    color: #fff;
}

.ngoImage {
    width: 100px;
    height: 80px;
    object-fit: contain;
    cursor: pointer;
}