/* .select {
    padding: 10px !important;
    border-radius: 16px !important;
}

.select fieldset {
    border-color: #E0E0E0;
} */

.select {
    margin-top: 8px;
    border: 1px solid #E4E4E4;
    cursor: pointer;
    padding: 9px 4px;
    height: 40px;
    border-radius: 8px;
    outline: none;
}

.select > option {
    color: #000;
}

.fullWidth {
    width: 100%;
}

.placeholder {
    color: #b3b3b3;
}