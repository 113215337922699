.header {
    display: block;
    width: 70%;
    margin: 0 auto;
}

.header>p {
    font-size: 13px;
    margin-top: 8px;
}

.iconButton {
    background: #fff;
    border: none;
    border-radius: 100%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.content {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.box {
    position: relative;
    background-color: #fff;
    /* box-shadow: 0 1px 5px #0D0A2C14, 0 1px 5px #0D0A2C14; */
    border-radius: 20px;
    padding: 28px;
    border: 1px solid var(--color-grey);
}

.boxHeader>.titleSecondary {
    color: #9291A5;
    font-size: 14px;
}

.boxHeader>.titlePrimary {
    font-size: 18px;
    margin-top: 4px;
}

.divider {
    background-color: #E5E5EF;
    width: 100%;
    height: 1px;
    margin: 24px 0px;
}

.meterChart {
    height: 320px;
    display: flex;
    align-items: flex-end;
    gap: 28px;
}

.noOfPledges {
    width: 42px;
    height: 170px;
    background-color: #FCB5C3;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.noOfPledges>.label {
    transform: rotate(270deg);
    white-space: nowrap;
    font-size: 11px;
}

.noOfPledges>.value {
    position: absolute;
    top: -30px;
}

.amountCollected {
    height: 100%;
    width: 42px;
    background-color: transparent;
}

.top {
    /* height: 55%; */
    background-color: #7FE47E;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: relative;
}

.middle {
    /* height: 25%; */
    background-color: #FFEB3A;
    position: relative;
}

.bottom {
    /* height: 20%; */
    background-color: #FF718B;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    position: relative;
}

.blockDescription {
    position: absolute;
    top: 10px;
    left: calc(100% - 5px);
}

.blockDescription>p {
    width: 360px;
    font-size: 12px;
    padding-left: 20px;
    white-space: wrap;

}

@media screen and (max-width: 500px) {
    .blockDescription>p {
        width: 40vw !important;
    }
}

.summary {
    margin-top: 24px;
}

.summary>.title {
    font-weight: 600;
    font-size: 14px;
}

.summary>.description {
    font-size: 12px;
    margin-top: 8px;
}