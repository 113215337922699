.container .header {
    text-align: center;
    color: #fff;
    max-width: 70%;
    margin: 0 auto;
}

.header p {
    font-size: 12px;
    margin-top: 10px;
}

.login {
    color: #000;
    font-size: 16px !important;
    position: fixed;
    bottom: 50px;
    text-align: center;
    transform: translateX(-50%);
    left: 50%;
}