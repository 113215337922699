.meterChart {
    height: 320px;
    display: flex;
    align-items: flex-end;
    gap: 28px;
}

.noOfPledges {
    width: 42px;
    height: 170px;
    background-color: #FCB5C3;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.noOfPledges>.label {
    transform: rotate(270deg);
    white-space: nowrap;
    font-size: 11px;
}

.noOfPledges>.value {
    position: absolute;
    top: -30px;
}

.amountCollected {
    height: 100%;
    width: 42px;
    background-color: transparent;
}

.top {
    /* height: 55%; */
    background-color: #7FE47E;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: relative;
}

.middle {
    /* height: 25%; */
    background-color: #FFEB3A;
    position: relative;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.bottom {
    /* height: 20%; */
    background-color: #FF718B;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    position: relative;
}

.blockDescription {
    position: absolute;
    /* top: 0px; */
    left: calc(100% - 5px);
    z-index: 1;
}

.top>.blockDescription {
    top: -8px;
    display: flex;
    align-items: center;
}

.middle>.blockDescription {
    top: calc(50% - 10px);
}

.bottom>.blockDescription {
    display: flex;
    flex-direction: column-reverse;
    bottom: -2px;
}

.blockDescription>p {
    width: 360px;
    font-size: 12px;
    padding-left: 20px;
    white-space: wrap;
}

@media screen and (max-width: 500px) {
    .blockDescription>p {
        width: 40vw !important;
    }
}